<template>
    <v-card>
        <v-form @submit="AddBudgetCheck($v.currentBudget)">
            <v-app-bar dense absolute text class="fixedToolbar" style="z-index: 2;">
                <v-toolbar-title v-bind:class="{'headline grey-text': !drawer, 'padLeft headline grey-text': drawer }">New Budget</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon type="submit" v-on:click.prevent="AddBudgetCheck($v.currentBudget)">
                    <v-icon color="green">fas fa-check</v-icon>
                </v-btn>
                <v-btn icon @click.native="$router.push({ path: '/dashboard/budget' })">
                    <v-icon color="red">fas fa-times</v-icon>
                </v-btn>
            </v-app-bar>

            <v-card-text style="padding-top: 0;">
                <v-container grid-list-md class="padContentLess">
                    <v-layout wrap>
                        <v-flex x12>
                            <v-autocomplete v-bind:items="curriencies"
                                            v-model="currentBudget.currency"
                                            label="Currency"
                                            chips
                                            hint="What Currency is this Income in?"
                                            persistent-hint
                                            :error-messages="currencyErrors"
                                            @input="$v.currentBudget.currency.$touch()"
                                            @blur="$v.currentBudget.currency.$touch()"
                                            item-text="currencyName"
                                            item-value="currencyShort">

                                <template slot="selection"
                                          slot-scope="data">
                                    <v-chip :input-value="data.selected"
                                            class="chip--select-multi">
                                        {{ data.item.currencyName }}
                                    </v-chip>
                                </template>

                                <template slot="item"
                                          slot-scope="data">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.currencyName"></v-list-item-title>
                                            <v-list-item-subtitle v-html="data.item.currencyShort"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field label="Budget Name" 
                                          v-model="currentBudget.budgetName"
                                          :error-messages="budgetNameErrors"
                                          @input="$v.currentBudget.budgetName.$touch()"
                                          @blur="$v.currentBudget.budgetName.$touch()"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <!--<v-autocomplete v-bind:items="account"
                                            v-model="currentBudget.account"
                                            label="Account"
                                            chips
                                            prepend-icon="fa-balance-scale"
                                            hint="What Account does this belong to?"
                                            persistent-hint
                                            :error-messages="accountErrors"
                                            @input="$v.currentBudget.account.$touch()"
                                            @blur="$v.currentBudget.account.$touch()"></v-autocomplete>-->

                            <v-autocomplete label="Account"
                                            :items="account"
                                            v-model="currentBudget.account"
                                            item-text="accountName"
                                            item-value="accountName"
                                            chips
                                            prepend-icon="fa-balance-scale"
                                            hint="What Account does this belong to?"
                                            persistent-hint
                                            :error-messages="accountErrors"
                                            @input="$v.currentBudget.account.$touch()"
                                            @blur="$v.currentBudget.account.$touch()">
                                <template slot="selection" slot-scope="data">
                                    <v-chip @input="data.parent.selectItem(data.item)"
                                            :input-value="data.selected"
                                            class="chip--select-multi"
                                            :key="JSON.stringify(data.item)">

                                        {{ data.item.accountName }}
                                    </v-chip>
                                </template>
                                <template slot="item" slot-scope="data">
                                    <template v-if="typeof data.item !== 'object'">
                                        <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="data.item.accountName"></v-list-item-title>
                                            <v-list-item-subtitle v-text="asMoney(data.item.accountAmount)"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                        <v-flex xs12>
                            <Category v-on:category="currentBudget.category = $event"
                                      v-bind:options="{prepend: 'fa-tags', hint: true}"></Category>
                        </v-flex>
                        <v-flex xs12>
                            <v-autocomplete v-bind:items="type"
                                            v-model="currentBudget.budgetType"
                                            label="budget Type"
                                            chips
                                            prepend-icon="fa-tag"
                                            hint="What Budget Type is this?"
                                            persistent-hint
                                            :error-messages="budgetTypeErrors"
                                            @input="$v.currentBudget.budgetType.$touch()"
                                            @blur="$v.currentBudget.budgetType.$touch()"></v-autocomplete>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field label="Budget Amount" 
                                          prepend-icon="fa-money-bill-alt" 
                                          type="number" min="1"
                                          v-model="currentBudget.budgetAmount"
                                          :error-messages="budgetAmountErrors"
                                          @change="copyBudgetAmountToRefillAmount()"
                                          @input="$v.currentBudget.budgetAmount.$touch()"
                                          @blur="$v.currentBudget.budgetAmount.$touch()"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field label="Refill Amount" 
                                          prepend-icon="fa-money-bill-alt" 
                                          type="number" min="1"
                                          v-model="currentBudget.refillAmount"
                                          :error-messages="refillAmountErrors"
                                          @input="$v.currentBudget.refillAmount.$touch(), stopCopyBudgetAmountToRefillAmount()"
                                          @blur="$v.currentBudget.refillAmount.$touch()"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-text-field label="Comment" prepend-icon="fa-comment" v-model="currentBudget.comment"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <v-divider></v-divider>
                        </v-flex>

                        <!-- Notifications -->

                        <v-flex xs12><label><b>Budget Overspent</b></label></v-flex>

                        <v-flex xs10 lg4>
                            <p>Notify me when amount spent has exceeded budgeted amount</p>
                        </v-flex>
                        <v-flex xs2 lg8>
                            <v-switch v-model="currentBudget.budgetOverspent"></v-switch>
                        </v-flex>


                        <v-flex xs12><label><b>Overspending Risk</b></label></v-flex>

                        <v-flex xs10 lg4>
                            <p>Notify me when there is a risk of overspending</p>
                        </v-flex>
                        <v-flex xs2 lg8>
                            <v-switch v-model="currentBudget.overspendingRisk"></v-switch>
                        </v-flex>

                    </v-layout>
                </v-container>
            </v-card-text>
        </v-form>
    </v-card>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, minValue } from 'vuelidate/lib/validators'

  import { globalFunctions, formatMoney } from '../../javascript/globalFunctions'
    
  
  export default {
    mixins: [validationMixin],
        components: {
            CurrencyConverter: () => import('../../components/currencyConverter/currencyConverter.vue'),
            Category: () => import('../../components/category/categorySelect.vue')
    },
    props: ['drawer'],
    data() {
      return {
        account: [],
        category: [],
        type: ["None", "Weekly", "Monthly", "Yearly"],
        curriencies: [],
        sameBudget: false,
        copyToRefill: true,
        currentBudget: {
          id: 0,
          budgetName: '',
          account: '',
          budgetType: '',
          budgetAmount: 0,
          amountSpent: 0,
          refillAmount: 0,
          //refillDate: '',
          currency: '',
          comment: '',
          category: [],

          //Notification
          budgetOverspent: true,
          overspendingRisk: true,

          //State of Current Object
          objectState: 'New',
          //show: false
        },
        items: []
      }
    },
    computed: {
        asMoney: function () {
            return (amount) => {
                return formatMoney(amount, 2, ".", ",")
            }
        },
      budgetNameErrors() {
        const errors = []
        if (!this.$v.currentBudget.budgetName.$dirty ) return errors
        this.sameBudget && errors.push('A Budget already has this Name. Enter a new Name of update Budget')
        !this.$v.currentBudget.budgetName.required && errors.push('Budget Name is required')
        return errors
      },
      accountErrors() {
        const errors = []
        if (!this.$v.currentBudget.account.$dirty) return errors
        !this.$v.currentBudget.account.required && errors.push('Account is required')
        return errors
      },
      budgetTypeErrors() {
        const errors = []
        if (!this.$v.currentBudget.budgetType.$dirty) return errors
        !this.$v.currentBudget.budgetType.required && errors.push('Budget Type is required')
        return errors
      },
      //categoryErrors() {
      //  const errors = []
      //  if (!this.$v.currentBudget.category.$dirty) return errors
      //  !this.$v.currentBudget.category.required && errors.push('Category is required')
      //  return errors
      //},
      currencyErrors() {
        const errors = []
        if (!this.$v.currentBudget.currency.$dirty) return errors
        !this.$v.currentBudget.currency.required && errors.push('Currency is required')
        return errors
      },
      budgetAmountErrors() {
        const errors = []
        if (!this.$v.currentBudget.budgetAmount.$dirty) return errors
        !this.$v.currentBudget.budgetAmount.minValue && errors.push('Budget Amount must be at least 1')
        return errors
      },
      refillAmountErrors() {
        const errors = []
        if (!this.$v.currentBudget.budgetAmount.$dirty) return errors
        !this.$v.currentBudget.budgetAmount.minValue && errors.push('Refill Amount must be at least 1')
        return errors
      },
      stopCopyBudgetAmountToRefillAmount() {
        this.copyToRefill = false
      }
    },
    methods: {
      //adjustCurrency: function () {
      //  if (this.currentBudget.account !== '') {
      //    let acc = accountManager.getAccountByName(this.currentBudget.account)

      //    if (this.currentBudget.currency === '') {
      //      this.currentBudget.currency = acc.accountCurrency
      //    }
      //    else if (this.currentBudget.currency !== acc.accountCurrency) {
      //      this.conversion = true
      //    }
      //    else if (this.currentBudget.currency === acc.accountCurrency) {
      //      this.conversion = false
      //    }
      //  }
      //},
      budgetCheck: function ($v) {
        $v.$touch(); //Triggers Error Messages

        if (this.currentBudget.budgetAmount <= 0 || !globalFunctions.isIntOrFloat(this.currentBudget.budgetAmount) || this.currentBudget.budgetAmount === "") { this.currentBudget.budgetAmount = 0 }

        if ($v.budgetName.$invalid || $v.account.$invalid) {
          return false
        }

        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].budgetName.toLowerCase() === this.currentBudget.budgetName.toLowerCase()) {
            this.sameBudget = true
            return false
          }
          if (i === this.items.length - 1) {
            this.sameBudget = false
          }
        }

        return true
      },
      AddBudgetCheck: function ($v) {
        if (this.budgetCheck($v)) {
          this.AddBudget($v);
        }
      },
      AddBudget: function ($v) {
        let newEntry = JSON.parse(JSON.stringify(this.currentBudget))

        this.addDialog = false

        newEntry.id = globalFunctions.getNextIdForObject(this.items)

        this.$store.commit('addNewBudgetToStore', newEntry)

        this.$router.push({ path: '/dashboard/budget' })
      },
      getAvailableCurriencies: function () {
        let cur = this.$store.state.Currencies

        for (let i = 0; i < cur.length; i++) {
          if (cur[i].ObjectState !== "Delete") {
            this.curriencies.push(cur[i])
          }
        }
      },
      copyBudgetAmountToRefillAmount() {
        if (this.currentBudget.refillAmount === 0 || this.currentBudget.refillAmount === '') {
          this.copyToRefill = true
        }

        if (this.copyToRefill === true) {
          this.currentBudget.refillAmount = this.currentBudget.budgetAmount
        }

      },
    },
    beforeMount() {
      this.items = this.$store.state.Budgets

      let cat = this.$store.state.Categories
      for (let i = 0; i < cat.length; i++) {
        this.category.push(cat[i].categoryName)
      }

      let acc = this.$store.state.Accounts
      for (let i = 0; i < acc.length; i++) {
        this.account.push(acc[i])
      }

      this.getAvailableCurriencies()
    },
    validations: {
      currentBudget: {
        budgetName: {
          required
        },
        account: {
          required
        },
        currency: {
          required
        },
        //category: {
        //  required
        //},
        budgetType: {
          required
        },
        budgetAmount: {
          required,
          minValue: minValue(1)
        },
        refillAmount: {
          required,
          minValue: minValue(1)
        },
        refillDate: {
          required
        }
      }
    }
  }
</script>
